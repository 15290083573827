import { useEffect, useState } from "react";

function useLocalStorage(itemName, initialValue) {
  const [dids, setDids] = useState(initialValue);
  const [sincronizedDids, setSincronizedDids] = useState(true);
  useEffect(() => {
    try {
      const localStorageDids = localStorage.getItem(itemName);
      let parsedDids;
      if (!localStorageDids) {
        localStorage.setItem(itemName, JSON.stringify(initialValue));
        parsedDids = initialValue;
      } else {
        parsedDids = JSON.parse(localStorageDids);
      }
      setDids(parsedDids);
      setSincronizedDids(true);
    } catch (error) {
      console.log(error);
    }
  }, [sincronizedDids, itemName]);

  const saveDids = (newItem) => {
    localStorage.setItem(itemName, JSON.stringify(newItem));
    setDids(newItem);
  };

  const deleteDids = () => {
    localStorage.removeItem(itemName);
    setDids({
      issuers: [
        {
          did: "did:quarkid:EiANRoiwZzyYpSz3RWhFhuesH2x-hx8hGuFDu-arhbaFRA",
          ref: "Government",
          vcType: "citizen",
          created: true,
          iconUrl:
            "https://cdn0.iconfinder.com/data/icons/citycons/150/Citycons_institution-256.png",
        },
        {
          did: "did:quarkid:EiBJpk86iip7KiuAukLbNRdLvsSV3tguNlxBuBD3Gw2cRg",
          ref: "Southern University",
          vcType: "university",
          created: true,
          iconUrl:
            "https://cdn0.iconfinder.com/data/icons/social-circle-3/72/Classmates-256.png",
        },
        {
          did: "did:quarkid:EiAir4zUcBDef-fqPyYQQD3znie-29rNqMGrrrYgRPtCOg",
          ref: "Naturgy",
          vcType: "naturgy",
          created: true,
          iconUrl:
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSZ3K-fYTyHdMIaXGdbsO2YhIzTd8rNrDCDT9S3BM27mGw0LvE4eOaHXBstVF7HVLbrfUQ&usqp=CAU",
        },
        {
          did: "did:quarkid:EiDvoQgdF4OOBm0fNmTJGGgqsIEE9FwUkEVMvYGpG0h3Hg",
          ref: "Santander Bank",
          vcType: "santander",
          created: true,
          iconUrl:
            "https://i.ibb.co/0cZdRcM/bcda8a3eaa78befafba68b851b2cdfc0.png",
        },
      ],
      holders: [],
    });
  };

  const deleteDid = (param) => {
    const oldDids = dids;
    const didToDelete = oldDids.holders.find((did) => did.did === param);
    console.log("Did to delete: ", didToDelete);
    console.log("Delete did: ", param);
  };

  const sincronizeItem = () => {
    setSincronizedDids(false);
  };

  return { dids, saveDids, sincronizeItem, deleteDids, deleteDid };
}

export { useLocalStorage };
