import { useContext, useState } from "react";
import { DidContext } from "../../context";
import { CodeBracketIcon } from "@heroicons/react/24/outline";
import { CheckIcon } from "@heroicons/react/16/solid";
import Loader from "../Loader";
import "./Credential.css";

const Credential = ({ credential }) => {
  const context = useContext(DidContext);

  const [loading, setLoading] = useState(false);

  const selectVc = (id) => {
    setLoading(true);

    const verificationVc = JSON.parse(context.credentials).find(
      (credential) => credential.id === id
    );
    context.verifyCredential(verificationVc);
  };

  const openRequest = (id) => {
    const verificationVc = context.vcsById.find(
      (credential) => credential.vc.data.data.id === id
    );

    context.setVerificationRequest((prevState) => ({
      ...prevState,
      param: { vc: verificationVc.vc },
      response: verificationVc.verificationResponse,
    }));
    context.setOpenCredentialRequest(true);
  };
  return (
    <div className={`CredentialContainer `}>
      <div
        style={{
          backgroundImage: `url(${credential.vc.data.styles.hero.uri})`,
          color: `${credential.vc.data.styles.text.color}`,
          backgroundSize: "190%",
          backgroundRepeat: "no-repeat",
        }}
        className={`credential`}
      >
        <h6>
          {" "}
          <figure
            style={{
              background: ` ${credential.vc.data.styles.background.color}`,
            }}
            className={`credential-thumbnail`}
          >
            <img
              src={credential.vc.data.styles.thumbnail.uri}
              alt="Thumbnail"
            ></img>
          </figure>
          {credential.vc.data.data.issuer.name}
        </h6>
        <div>
          <p>
            {credential.vc.data.data.credentialSubject.name}{" "}
            {credential.vc.data.data.credentialSubject.lastName}
          </p>
          <p></p>
          <p>{credential.vc.data.data.credentialSubject.birth}</p>
        </div>
      </div>
      <div className="credential-buttonContainer">
        {credential.verified && (
          <div
            className="credential-button request"
            onClick={() => openRequest(credential.vc.data.data.id)}
          >
            <CodeBracketIcon width={"1vw"} color="#444444" />
          </div>
        )}

        {credential.verified ? (
          <div className="credential-button verified">
            <CheckIcon width={"1vw"} />
          </div>
        ) : loading ? (
          <div className="credential-button verifying">
            {" "}
            <Loader type={"circular"} loaderWidth={"1vw"} />
          </div>
        ) : (
          <div
            onClick={() => selectVc(credential.vc.data.data.id)}
            className="credential-button verify"
          >
            <CheckIcon width={"1vw"} color="#444444" />
          </div>
        )}
      </div>
    </div>
  );
};

export default Credential;
