import { useContext } from "react";
import { DidContext } from "../../context/index.jsx";
import Button from "../Button/index.jsx";
import CopyToClipboard from "../CopyToClipboard";
import { CodeBracketIcon } from "@heroicons/react/24/outline";

const InvitationManualProcess = () => {
  const context = useContext(DidContext);
  const buttonValidation = () => {
    if (!context.oobContentData) {
      return true;
    } else if (!context.verifiableDid) {
      return true;
    } else if (context.processRequest.status === "200") {
      return true;
    }
  };
  return (
    <div className="InvitationManualProcess">
      <h2>
        Process invitation (manual process):{" "}
        <div
          className="request-button"
          onClick={() => context.setOpenProcessRequest(true)}
        >
          {" "}
          <CodeBracketIcon width={"1.1vw"} />
        </div>{" "}
      </h2>
      <Button
        onClick={context.processInvitation}
        text={"Process & List"}
        color={"green"}
        disabled={buttonValidation()}
      />
      <CopyToClipboard
        textarea={false}
        disabled={true}
        inputValue={
          context.manualProcessData.did
            ? context.manualProcessData.did
            : "Select holder DID from My Dids page to continue"
        }
      />

      <CopyToClipboard
        inputValue={context.oobContentData}
        textarea={true}
        disabled={true}
      />
      <p>Response</p>
      <CopyToClipboard
        inputValue={context.processResponse}
        textarea={false}
        disabled={true}
      ></CopyToClipboard>
    </div>
  );
};

export default InvitationManualProcess;
