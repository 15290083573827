import CreateDID from "../components/CreateDID";
import MyDIDs from "../components/MyDIDs";
import GetDocument from "../components/GetDocument";
import Modal from "../components/Modal";
import { useContext, useState } from "react";
import { DidContext } from "../context";
import ShowRequest from "../components/ShowRequest";
import Button from "../components/Button";

const DID = () => {
  const context = useContext(DidContext);
  const [credentialRequestStep, setCredentialRequestStep] = useState(false);
  const closeCredentialsRequest = () => {
    context.setOpenCredentialsRequest(false);
    setCredentialRequestStep(false);
  };
  const closeCredentialRequest = () => {
    context.setOpenCredentialRequest(false);
    setCredentialRequestStep(false);
  };

  return (
    <>
      <MyDIDs />
      {context.openCreate && (
        <Modal>
          <CreateDID />
        </Modal>
      )}
      {context.openDocument && (
        <Modal>
          <GetDocument />
        </Modal>
      )}
      {context.openCredentialsRequest && (
        <Modal>
          <ShowRequest
            method={"GET"}
            title={
              credentialRequestStep
                ? "For each id from first step (example for one VC)"
                : "Get Credentials (first step)"
            }
            endpoint={
              credentialRequestStep
                ? context.credentialsByIdRequest.endpoint
                : context.credentialsRequest.endpoint
            }
            response={
              credentialRequestStep
                ? context.credentialsByIdRequest.response
                : context.credentialsRequest.response
            }
            close={() => closeCredentialsRequest()}
          />

          <Button
            color={"transparent"}
            text={credentialRequestStep ? "First step" : "Second step"}
            onClick={() => setCredentialRequestStep(!credentialRequestStep)}
          />
        </Modal>
      )}
      {context.openCredentialRequest && (
        <Modal>
          <ShowRequest
            method={"GET"}
            title={"Verification"}
            endpoint={context.verificationRequest.endpoint}
            body={context.verificationRequest.param}
            response={context.verificationRequest.response}
            close={() => closeCredentialRequest()}
          />
        </Modal>
      )}
    </>
  );
};

export default DID;
