import { useContext } from "react";
import { DidContext } from "../../context";
import "./Button.css";

const Button = ({ text, onClick, disabled, color }) => {
  const context = useContext(DidContext);

  return (
    <div className="ButtonContainer">
      <button
        className={`button ${context.theme} ${color}`}
        disabled={disabled}
        onClick={onClick}
      >
        {text}
      </button>
    </div>
  );
};

export default Button;
