import { useContext, useEffect, useState } from "react";
import { DidContext } from "../../context";
import { MoonIcon, SunIcon } from "@heroicons/react/24/outline";
import CopyToClipboard from "../CopyToClipboard";
import "./Statebar.css";

const StateBar = () => {
  const context = useContext(DidContext);
  const [data, setData] = useState({
    did: "No Holder DID assigned. Select or create one",
    ref: "",
  });
  useEffect(() => {
    context.verifiableDid.did &&
      setData({
        did: context.verifiableDid.did,
        ref: context.verifiableDid.ref,
      });
  }, [context.verifiableDid]);
  return (
    <div className={`StateBar ${context.theme} `}>
      <div className="statebar-role">
        <div className={`statebar-role-button active`}>
          <div className="status">
            <div
              className={`status-check ${
                context.verifiableDid.created
                  ? "status-check-true"
                  : "status-check-false"
              } `}
            ></div>
          </div>
          Holder
        </div>
      </div>
      <div className="statebar-container">
        {" "}
        <CopyToClipboard
          inputValue={data.did}
          textarea={false}
          disabled={true}
        ></CopyToClipboard>
      </div>
      <div className="ref-container">
        {" "}
        <span>Reference:</span>
        <p>{data.ref && data.ref}</p>
      </div>
      <div
        className="theme"
        onClick={() => context.setDarkMode(!context.darkMode)}
      >
        {context.darkMode ? (
          <SunIcon className="sun-icon" />
        ) : (
          <MoonIcon className="moon-icon" />
        )}
      </div>
    </div>
  );
};

export default StateBar;
