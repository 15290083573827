import { useContext } from "react";
import { DidContext } from "../../context";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import Button from "../Button";
import Loader from "../Loader";
import RenderCredentials from "../RenderCredentials";
import "./MyDIDs.css";

const MyDIDs = () => {
  const context = useContext(DidContext);

  const openModal = (did, ref) => {
    context.setOpenDocument(true);
    context.getDidDocument(did, ref);
  };

  const selectVerifiableDid = (value) => {
    context.setVerifiableDid(value);
  };

  return (
    <div
      className={`MyDIDs ${
        context.openCreate || context.openDocument
          ? "modal-active"
          : "modal-inactive"
      }`}
    >
      <h2>My DIDs</h2>
      <p className="myDids-text">
        Here are the available identifiers to operate within the application.
        You can issue credentials with the "Issuer DIDs" to the various "Holder
        DIDs" you wish to create.
      </p>

      <h4>Issuer DIDs</h4>
      {context.dids.issuers ? (
        <div className={`my-dids-container ${context.theme}`}>
          <div className="my-dids-labels">
            <p className="my-dids-labels-select"></p>
            <p className="my-dids-labels-did">DID</p>
            <p className="my-dids-labels-ref">Reference</p>
            <p className="my-dids-labels-doc">Document</p>
          </div>{" "}
          {context.dids.issuers.map((did) => {
            return (
              <div
                key={did.did}
                className={`my-dids-did ${
                  context.issuerDid.did === did.did
                    ? "did-selected"
                    : "did-not-selected"
                } `}
              >
                <div
                  className={`my-dids-icon-container ${
                    context.issuerDid.did === did.did
                      ? "did-icon-selected"
                      : "did-icon-not-selected"
                  } `}
                >
                  <img
                    className="my-dids-icon-image"
                    src={did.iconUrl}
                    alt=""
                  />
                </div>

                <p className="my-dids-did-code">{did.did}</p>
                <p className="my-dids-did-ref">{did.ref}</p>
                <button
                  className="my-dids-did-doc"
                  onClick={() => openModal(did.did, did.ref)}
                  disabled={!did.created}
                >
                  View
                </button>
              </div>
            );
          })}
        </div>
      ) : (
        <Loader type={"circular"} loaderWidth={"4vw"} />
      )}

      <h4>Holder DIDs</h4>
      <div className="myDids-ButtonContainer">
        <Button
          onClick={() => context.setOpenCreate(true)}
          text={"Create Holder DID"}
          color={"green"}
        />
        <Button
          text={"Delete DIDs"}
          color={"orange"}
          onClick={context.removeDids}
        />
      </div>
      {context.dids.holders ? (
        <div className={`my-dids-container ${context.theme}`}>
          <div className="my-dids-labels">
            <p className="my-dids-labels-select">Select</p>
            <p className="my-dids-labels-did">DID</p>
            <p className="my-dids-labels-ref">Reference</p>
            <p className="my-dids-labels-doc">Document</p>
          </div>
          {context.dids.holders.map((did) => {
            return (
              <div
                key={did.did}
                className={`my-dids-did ${
                  context.verifiableDid.did === did.did
                    ? "did-selected"
                    : "did-not-selected"
                } `}
              >
                {did.created ? (
                  <div
                    className={`my-dids-icon-container ${
                      context.verifiableDid === did
                        ? "did-icon-selected"
                        : "did-icon-not-selected"
                    } `}
                  >
                    {context.verifiableDid.did === did.did ? (
                      <CheckCircleIcon width={"2vw"} />
                    ) : (
                      <CheckCircleIcon
                        onClick={() => selectVerifiableDid(did)}
                        width={"2vw"}
                        style={{ cursor: "pointer" }}
                      />
                    )}
                  </div>
                ) : (
                  <div className="my-dids-icon-container">
                    <Loader type={"radial"} loaderWidth={"3vw"} />
                  </div>
                )}

                <p className="my-dids-did-code">{did.did}</p>
                <p className="my-dids-did-ref">{did.ref}</p>
                <button
                  className="my-dids-did-doc"
                  onClick={() => openModal(did.did, did.ref)}
                  disabled={!did.created}
                >
                  View
                </button>
              </div>
            );
          })}
        </div>
      ) : (
        <Loader type={"circular"} loaderWidth={"4vw"} />
      )}
      {context.verifiableDid.did && <RenderCredentials />}
    </div>
  );
};
export default MyDIDs;
