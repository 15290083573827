import { createContext, useEffect, useState } from "react";
import { useLocalStorage } from "../utils/useLocalStorage";
import invitation from "../utils/invitation.js";
import { request } from "../utils/api.js";

export const DidContext = createContext();

export const DidProvider = ({ children }) => {
  // Theme
  const [darkMode, setDarkMode] = useState(true);
  const theme = darkMode ? "darkMode" : "lightMode";

  // Operational Dids
  const [issuerDid, setIssuerDid] = useState("");
  const [verifiableDid, setVerifiableDid] = useState("");
  const [credentials, setCredentials] = useState("");
  const [vcsById, setVcsById] = useState([]);

  const [didDocument, setDidDocument] = useState("");
  const { dids, saveDids, deleteDids } = useLocalStorage("MyDids", {
    version: "2.0",
    issuers: [
      {
        did: "did:quarkid:EiANRoiwZzyYpSz3RWhFhuesH2x-hx8hGuFDu-arhbaFRA",
        ref: "Government",
        vcType: "citizen",
        created: true,
        iconUrl:
          "https://cdn0.iconfinder.com/data/icons/citycons/150/Citycons_institution-256.png",
      },
      {
        did: "did:quarkid:EiBJpk86iip7KiuAukLbNRdLvsSV3tguNlxBuBD3Gw2cRg",
        ref: "Southern University",
        vcType: "university",
        created: true,
        iconUrl:
          "https://cdn0.iconfinder.com/data/icons/social-circle-3/72/Classmates-256.png",
      },
      {
        did: "did:quarkid:EiAir4zUcBDef-fqPyYQQD3znie-29rNqMGrrrYgRPtCOg",
        ref: "Naturgy",
        vcType: "naturgy",
        created: true,
        iconUrl:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSZ3K-fYTyHdMIaXGdbsO2YhIzTd8rNrDCDT9S3BM27mGw0LvE4eOaHXBstVF7HVLbrfUQ&usqp=CAU",
      },
      {
        did: "did:quarkid:EiDvoQgdF4OOBm0fNmTJGGgqsIEE9FwUkEVMvYGpG0h3Hg",
        ref: "Santander Bank",
        vcType: "santander",
        created: true,
        iconUrl:
          "https://i.ibb.co/0cZdRcM/bcda8a3eaa78befafba68b851b2cdfc0.png",
      },
    ],
    holders: [],
  });

  // Create DID
  const [openCreate, setOpenCreate] = useState(false);
  const [onCreate, setOnCreate] = useState(false);
  const [createNewDid, setCreateNewDid] = useState({
    did: "",
    processing: false,
    message: "",
  });
  const [createDidRequest, setCreateDidRequest] = useState({
    endpoint: "/dids/quarkid",
    body: {
      websocket: "https://sandbox-ssi-ws.extrimian.com",
      dwn: "",
      webhookURL: "",
      verificationRulesEndpoint: "",
      didMethod: "did:quarkid",
    },
    response: "",
  });

  async function createDid(data) {
    setCreateNewDid({
      did: "",
      processing: true,
      message: "Creating DID",
    });
    try {
      const response = await request(
        "put",
        createDidRequest.endpoint,
        createDidRequest.body
      );
      setCreateNewDid({
        did: response.data.did,
        processing: true,
        message: "DID was created succesfuly",
      });
      setCreateDidRequest((prevState) => ({
        ...prevState,
        response: JSON.stringify(response),
      }));
      addDids(data.role, {
        did: response.data.did,
        ref: data.ref,
        vcType: data.vcType,
        created: false,
      });
    } catch (error) {
      console.error("Error in API call:", error);
      setCreateNewDid({
        did: "failed",
        processing: false,
        message: error.message,
      });
    }
  }

  // Save DID
  const addDids = (role, didToAdd) => {
    const newDids = { ...dids };
    if (role === "issuers") {
      newDids.issuers.push(didToAdd);
    } else if (role === "holders") {
      newDids.holders.push(didToAdd);
    }

    saveDids(newDids);
  };

  // Delete DIDs
  const [openDeleteDids, setOpenDeleteDids] = useState(true);
  const removeDids = () => {
    setIssuerDid("");
    setVerifiableDid("");
    deleteDids();
  };

  // Resolve DID Document
  async function getDidResult(did, role, status) {
    while (!status) {
      try {
        const response = await request("get", `/dids/quarkid/${did}`);
        if (response.data.id) {
          createdDid(did, role);
          break;
        } else {
          await new Promise((resolve) => setTimeout(resolve, 30000));
        }
      } catch (error) {
        if (error.response && error.response.status === 500) {
          await new Promise((resolve) => setTimeout(resolve, 30000));
        } else {
          console.error("Error no esperado:", error);
          break;
        }
      }
    }
  }

  // Get DID Document
  const [openDocument, setOpenDocument] = useState(false);
  const [documentRequest, setDocumentRequest] = useState({
    endpoint: "/dids/quarkid/",
    param: "",
    response: "",
  });

  const getDocumentEndpoint = (param) => {
    const newEndpoint = `${documentRequest.endpoint}${param}`;
    return newEndpoint;
  };
  async function getDidDocument(did, ref) {
    const variableEndpoint = getDocumentEndpoint(did);
    const response = await request("get", variableEndpoint);
    setDidDocument({
      reference: ref,
      document: JSON.stringify(response.data),
    });
    setDocumentRequest((prevState) => ({
      ...prevState,
      param: variableEndpoint,
      response: response,
    }));
  }

  // Add DID to saved list
  const createdDid = (value, role) => {
    const newDids = { ...dids };
    let checkedDid;
    if (role === "issuers") {
      checkedDid = newDids.issuers.find((did) => did.did === value);
    } else if (role === "holders") {
      checkedDid = newDids.holders.find((did) => did.did === value);
    }
    checkedDid.created = true;

    saveDids(newDids);
  };

  // Check DID status
  useEffect(() => {
    const pendingIssuerCreations = dids.issuers.filter((did) => !did.created);
    const pendingHoldersCreations = dids.holders.filter((did) => !did.created);
    pendingIssuerCreations.forEach((did) =>
      getDidResult(did.did, "issuers", did.created)
    );
    pendingHoldersCreations.forEach((did) =>
      getDidResult(did.did, "holders", did.created)
    );
  }, [dids]);

  // Invitation message
  const [openInvitationRequest, setOpenInvitationRequest] = useState(false);
  const [invitationRequest, setInvitationRequest] = useState({
    endpoint: "/credentialsbbs/wacioob",
    param: "",
    response: "",
  });
  const [oobContentData, setOobContentData] = useState("");
  const [showQr, setshowQr] = useState(false);
  const [invitationError, setInvitationError] = useState("");
  const [invitationId, setInvitationId] = useState("");
  const [formData, setFormData] = useState({
    did: "",
    issuer: "",
    name: "",
    lastName: "",
    birth: "",
    value1: "",
    value2: "",
    value3: "",
  });

  const getExistingCredential = () => {
    const existingIssuer = vcsById
      .map((vc) => vc.vc.data.data.issuer.name)
      .includes(issuerDid.ref);
    return existingIssuer;
  };

  async function generateInvitation() {
    const existingCredential = getExistingCredential();
    setOobContentData("");
    const data = invitation(
      issuerDid.vcType,
      formData.did,
      formData.issuer,
      formData.name,
      formData.lastName,
      formData.birth,
      formData.value1,
      formData.value2,
      formData.value3
    );

    if (existingCredential) {
      setInvitationError(
        "This holder DID already has a credential from this issuer created "
      );
    } else {
      const response = await request("put", invitationRequest.endpoint, data);
      // const response = await credentialsbbsWacioob(data);
      setOobContentData(response.data.oobContentData);
      setInvitationId(response.data.invitationId);
      setshowQr(true);
      setInvitationRequest({
        endpoint: "/credentialsbbs/wacioob",
        param: data,
        response: response,
      });
    }
  }

  // Reset form component
  useEffect(() => {
    setshowQr(false);
    setOobContentData("");
    setInvitationId("");
    setFormData({
      did: issuerDid.did,
      issuer: issuerDid.ref,
      name: "",
      lastName: "",
      birth: "",
      value1: "",
      value2: "",
      value3: "",
    });
    setProcessResponse("");
    setInvitationError("");
  }, [issuerDid.did]);

  // Invitation Manual Process
  const [openProcessRequest, setOpenProcessRequest] = useState(false);
  const [processRequest, setProcessRequest] = useState({
    endpoint: "/credentialsbbs/waci",
    param: "",
    response: "",
    status: "",
  });
  const [processResponse, setProcessResponse] = useState("");
  const [manualProcessData, setManualProcessData] = useState({
    did: "",
    message: "",
  });

  async function processInvitation() {
    setProcessResponse("Creating verifiable credential..");
    const response = await request(
      "put",
      processRequest.endpoint,
      manualProcessData
    );
    setTimeout(() => {
      setProcessRequest({
        endpoint: "/credentialsbbs/waci",
        param: manualProcessData,
        response: response,
        status: JSON.stringify(response.status),
      });
      setProcessResponse(
        "Credential generated successfully. Go to My DIDs to visualize it."
      );
    }, 2500);
  }

  useEffect(() => {
    setManualProcessData({
      did: verifiableDid.did,
      message: oobContentData,
    });
    setProcessResponse("");
  }, [oobContentData, verifiableDid.did]);

  // Get credentials
  const [openCredentialsRequest, setOpenCredentialsRequest] = useState(false);
  const [credentialsRequest, setCredentialsRequest] = useState({
    endpoint: `/credentialsbbs?did=`,
    response: "",
  });
  const updateCredentials = () => {
    if (verifiableDid.did) {
      setVcsById([]);
      getCredentials();
    }
  };

  async function getCredentials() {
    setVcsById([]);
    const newEndpoint = `/credentialsbbs?did=${verifiableDid.did}`;
    const response = await request("get", newEndpoint);
    setCredentials(JSON.stringify(response.data));
    setCredentialsRequest({
      endpoint: newEndpoint,
      response: response,
    });
    getIds(response.data);
  }

  useEffect(() => {
    updateCredentials();
  }, [verifiableDid, processResponse]);

  // Get credential by ID
  const [credentialsByIdRequest, setCredentialsByIdRequest] = useState({
    endpoint: "/credentialsbbs/vcid?vcId=",
    response: "",
  });

  const getIds = (vcs) => {
    vcs.forEach((vc) => {
      getCredentialById(vc.id);
    });
  };

  async function getCredentialById(param) {
    const newEndpoint = `/credentialsbbs/vcid?vcId=${param}`;
    const response = await request("get", newEndpoint);
    const credentialObject = {
      vc: response.data,
      verified: false,
      verificationResponse: "",
    };
    setVcsById((prevVcsById) => [...prevVcsById, credentialObject]);
    setCredentialsByIdRequest({
      endpoint: newEndpoint,
      response: response,
    });
  }

  // Verify
  const [openCredentialRequest, setOpenCredentialRequest] = useState(false);
  const [verificationRequest, setVerificationRequest] = useState({
    endpoint: "/credentialsbbs/verifier",
    param: "",
    response: "",
  });
  async function verifyCredential(param) {
    setVerificationRequest((prevState) => ({ ...prevState, param: param }));
    const body = { vc: param.vc };
    const response = await request("put", verificationRequest.endpoint, body);
    setVcsById((prevVcsById) =>
      prevVcsById.map((credential) =>
        credential.vc.data.data.id === param.id
          ? {
              ...credential,
              verified: true,
              verificationResponse: JSON.stringify(response),
            }
          : credential
      )
    );
  }

  return (
    <DidContext.Provider
      value={{
        darkMode,
        theme,
        setDarkMode,
        issuerDid,
        setIssuerDid,
        verifiableDid,
        setVerifiableDid,
        didDocument,
        setDidDocument,
        documentRequest,
        dids,
        saveDids,
        openCreate,
        setOpenCreate,
        createDid,
        createNewDid,
        setCreateNewDid,
        createDidRequest,
        getDidDocument,
        openDocument,
        setOpenDocument,
        openDeleteDids,
        setOpenDeleteDids,
        oobContentData,
        setOobContentData,
        showQr,
        invitationId,
        generateInvitation,
        invitationError,
        onCreate,
        setOnCreate,
        invitationRequest,
        openInvitationRequest,
        setOpenInvitationRequest,
        processResponse,
        manualProcessData,
        processInvitation,
        openProcessRequest,
        setOpenProcessRequest,
        processRequest,
        getCredentials,
        credentialsRequest,
        openCredentialsRequest,
        setOpenCredentialsRequest,
        openCredentialRequest,
        setOpenCredentialRequest,
        credentials,
        vcsById,
        updateCredentials,
        setVcsById,
        credentialsByIdRequest,
        verifyCredential,
        verificationRequest,
        setVerificationRequest,
        removeDids,
        setFormData,
        formData,
      }}
    >
      {children}
    </DidContext.Provider>
  );
};
