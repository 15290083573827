import { useContext, useEffect, useState } from "react";
import { DidContext } from "../../context";
import {
  CheckCircleIcon,
  CodeBracketIcon,
  ExclamationCircleIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import Button from "../Button";
import Loader from "../Loader";
import CopyToClipboard from "../CopyToClipboard";
import ShowRequest from "../ShowRequest";
import "./CreateDID.css";

const CreateDID = () => {
  const context = useContext(DidContext);
  const [creationRef, setCreationRef] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [newDid, setNewDid] = useState("");
  const [succsesfulCreation, setSuccsesfulCreation] = useState(false);
  const [showRequest, setShowRequest] = useState(false);

  // Close Modal
  const closeModal = () => {
    if (newDid !== "" || context.createNewDid.did === "failed") {
      setSuccsesfulCreation(false);
      setMessage("");
      setNewDid("");
      context.setCreateNewDid({
        did: "",
        processing: false,
        message: "",
      });
      context.setOnCreate(false);
    }
    context.setOpenCreate(false);
  };

  // Get reference
  const handleInputChange = (event) => {
    setCreationRef(event.target.value);
  };

  // Form validation
  const handleCreate = () => {
    const holderRefValues = context.dids.holders
      .map((did) => did.ref)
      .includes(creationRef);
    if (creationRef === "") {
      setMessage("Insert reference value");
    } else if (holderRefValues) {
      setMessage("Reference already exists, choose another one.");
    } else {
      setMessage("Creating DID");
      setLoading(true);
      context.setOnCreate(true);
      context.createDid({
        ref: creationRef,
        role: "holders",
        vcType: false,
      });
    }
  };

  // Check succsesful creation
  useEffect(() => {
    if (
      context.createNewDid.did !== "" &&
      context.createNewDid.did !== "failed"
    ) {
      setSuccsesfulCreation(true);
      setMessage("DID successfully created");
      setNewDid(context.createNewDid.did);
      setLoading(false);
    } else if (context.createNewDid.did === "failed") {
      setSuccsesfulCreation(false);
      setMessage(`${context.createNewDid.message}. Try again. `);
      context.setOnCreate(false);
    }
  }, [context]);

  //  Renders
  const renderCreate = () => {
    return (
      <>
        <h2>
          Create new DID{" "}
          {context.onCreate && (
            <div
              className="request-button"
              onClick={() => setShowRequest(true)}
            >
              {" "}
              <CodeBracketIcon width={"1.1vw"} />
            </div>
          )}
        </h2>
        <XCircleIcon className="Modal-close" onClick={() => closeModal()} />
        <div className="create-did-container-buttons">
          <p className={`create-did-buttons-button active`}>Holder</p>
        </div>
        <div className="create-did-subcontainer">
          <div className="create-did-container-inputs">
            <label htmlFor="">Reference:</label>
            <input type="text" onChange={handleInputChange} maxLength={30} />
            <label htmlFor="">Websocket:</label>
            <input
              type="text"
              value={"https://sandbox-ssi-ws.extrimian.com"}
              disabled
            />
          </div>
        </div>

        <Button
          onClick={handleCreate}
          disabled={context.onCreate}
          text={"Create DID"}
          color={"green"}
        />
        <p className="create-did-message">{message}</p>
      </>
    );
  };

  const renderCreation = () => {
    return (
      <div className="response-container">
        <h2>
          Create new DID{" "}
          {context.onCreate && (
            <div
              className="request-button"
              onClick={() => setShowRequest(true)}
            >
              {" "}
              <CodeBracketIcon width={"1.1vw"} />
            </div>
          )}
        </h2>
        <XCircleIcon className="Modal-close" onClick={() => closeModal()} />
        {loading ? (
          <div className="response-container-icon">
            <Loader type={"circular"} loaderWidth={"4vw"} />
          </div>
        ) : (
          renderResponseIcon()
        )}
        <p className="response-container-message">{message}</p>
        <p className="response-container-labels">Reference:</p>
        <CopyToClipboard
          inputValue={creationRef}
          textarea={false}
          disabled={true}
        />
        <p className="response-container-labels">New DID:</p>
        <CopyToClipboard inputValue={newDid} textarea={false} disabled={true} />
      </div>
    );
  };

  const renderResponseIcon = () => {
    return succsesfulCreation ? (
      <div className="response-container-icon">
        <CheckCircleIcon width={"4vw"} style={{ color: "#0abb89" }} />
      </div>
    ) : (
      <div className="response-container-icon">
        <ExclamationCircleIcon width={"4vw"} style={{ color: "#bb330a" }} />
      </div>
    );
  };
  return (
    <div className={`create-did-container ${context.theme}`}>
      {!context.onCreate ? (
        renderCreate()
      ) : !showRequest ? (
        renderCreation()
      ) : (
        <ShowRequest
          method={"PUT"}
          endpoint={context.createDidRequest.endpoint}
          response={context.createDidRequest.response}
          body={context.createDidRequest.body}
          title={"Create new DID"}
          close={() => closeModal()}
        />
      )}
    </div>
  );
};

export default CreateDID;
