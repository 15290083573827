import { useContext } from "react";
import { DidContext } from "../../context/index.jsx";
import CopyToClipboard from "../CopyToClipboard";
import InvitationForm from "../InvitationForm/index.jsx";
import DidSelector from "../DidSelector/index.jsx";
import { CodeBracketIcon } from "@heroicons/react/24/outline";

const InvitationMessage = () => {
  const context = useContext(DidContext);

  return (
    <div>
      <h2>
        Invitation message with QR Code{" "}
        <div
          className="request-button"
          onClick={() => context.setOpenInvitationRequest(true)}
        >
          {" "}
          <CodeBracketIcon width={"1.1vw"} />
        </div>
      </h2>
      <p> Issuer:</p>
      <DidSelector />
      <p> Issuer DID:</p>

      <CopyToClipboard
        inputValue={
          context.issuerDid.did
            ? context.issuerDid.did
            : "Select issuer DID from My Dids page to continue"
        }
        textarea={false}
        disabled={true}
      />

      <InvitationForm />
      <p>Invitation ID:</p>
      <CopyToClipboard
        inputValue={context.invitationId}
        textarea={false}
        disabled={true}
      />
      <p>oobContentData: </p>
      <CopyToClipboard
        inputValue={context.oobContentData}
        textarea={true}
        disabled={true}
      />
    </div>
  );
};

export default InvitationMessage;
