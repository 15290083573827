import loader from "../../assets/loader5.svg";
import loader2 from "../../assets/loader3.svg";

import "./Loader.css";

const Loader = ({ type, loaderWidth }) => {
  const renderView = (param) => {
    switch (param) {
      case "radial":
        return <img src={loader2} alt="loading" className="loader" />;
      case "circular":
        return <img src={loader} alt="loading" className="loader" />;
      default:
        <p>loading..</p>;
    }
  };
  const loaderSize = {
    width: loaderWidth,
    height: loaderWidth,
  };

  return (
    <span className="loader-container" style={loaderSize}>
      {renderView(type)}{" "}
    </span>
  );
};

export default Loader;
