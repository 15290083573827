// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ShowRequest {
  position: relative;
  width: 100%;
  width: 50vw;
}

.show-request-method {
  border: 2px solid #999999;
  width: 3.5vw;
  height: 1.5vw;
  font-size: 1.1vw;
  margin-bottom: 0.5vw;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
}
`, "",{"version":3,"sources":["webpack://./src/components/ShowRequest/ShowRequest.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,WAAW;AACb;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,aAAa;EACb,gBAAgB;EAChB,oBAAoB;EACpB,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;AACpB","sourcesContent":[".ShowRequest {\n  position: relative;\n  width: 100%;\n  width: 50vw;\n}\n\n.show-request-method {\n  border: 2px solid #999999;\n  width: 3.5vw;\n  height: 1.5vw;\n  font-size: 1.1vw;\n  margin-bottom: 0.5vw;\n  border-radius: 4px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  overflow-x: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
