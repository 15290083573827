import { useContext, useState } from "react";
import "./DidSelector.css";
import { DidContext } from "../../context";
import { ArrowsUpDownIcon } from "@heroicons/react/24/outline";

const DidSelector = () => {
  const context = useContext(DidContext);
  const [openRefs, setOpenRefs] = useState(false);
  const selectDid = (value) => {
    context.setIssuerDid(value);
    setOpenRefs(false);
  };
  return (
    <div className={`DidSelector ${context.theme}`}>
      <div
        className="DidSelector-selected-did"
        onClick={() => setOpenRefs(!openRefs)}
      >
        {context.issuerDid.did ? context.issuerDid.ref : "Select DID"}

        <ArrowsUpDownIcon
          className="didSelector-arrow-icon"
          onClick={() => setOpenRefs(!openRefs)}
        />
      </div>
      {openRefs && (
        <div className="DidSelector-dids">
          {context.dids.issuers.map((issuer) => {
            return (
              <p key={issuer.did} onClick={() => selectDid(issuer)}>
                <img src={issuer.iconUrl} alt="logo" /> {issuer.ref}
              </p>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default DidSelector;
