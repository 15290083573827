import { useContext, useState } from "react";
import { DidContext } from "../../context";
import { CodeBracketIcon, XCircleIcon } from "@heroicons/react/24/outline";
import CopyToClipboard from "../CopyToClipboard";
import "./GetDocument.css";
import ShowRequest from "../ShowRequest";

const GetDocument = () => {
  const context = useContext(DidContext);
  const [showRequest, setShowRequest] = useState(false);
  return (
    <div className={`get-document-container`}>
      {showRequest ? (
        <ShowRequest
          method={"GET"}
          endpoint={context.documentRequest.param}
          response={context.documentRequest.response}
          body={context.documentRequest.body}
          close={() => context.setOpenDocument(false)}
          title={"Did Document"}
        />
      ) : (
        <>
          <XCircleIcon
            className="Modal-close"
            onClick={() => context.setOpenDocument(false)}
          />
          <h2 style={{ marginBottom: "0.5vw" }}>
            Did Document{" "}
            <div
              className="request-button"
              onClick={() => setShowRequest(!showRequest)}
            >
              {" "}
              <CodeBracketIcon width={"1.1vw"} />
            </div>
          </h2>
          <p style={{ marginTop: "0.5vw" }}>
            Reference: <strong>{context.didDocument.reference}</strong>
          </p>
          Document:
          <CopyToClipboard
            inputValue={context.didDocument.document}
            textarea={true}
            disabled={true}
          />
        </>
      )}
    </div>
  );
};

export default GetDocument;
