import { useContext } from "react";
import { DidContext } from "../../context";
import "./RenderCredential.css";
import Credential from "../Credential";
import { CodeBracketIcon } from "@heroicons/react/24/outline";

const RenderCredentials = () => {
  const context = useContext(DidContext);
  return (
    <div className="Render">
      <h2>
        Credentials{" "}
        <div
          className="request-button"
          onClick={() => context.setOpenCredentialsRequest(true)}
        >
          {" "}
          <CodeBracketIcon width={"1.1vw"} />
        </div>
      </h2>
      <div className="renderVc-container">
        {context.vcsById.length > 0
          ? context.vcsById.map((credential) => (
              <Credential
                credential={credential}
                key={credential.vc.data.data.id}
              />
            ))
          : "This holder DID does not have any credential. Go to Generate Credentials to acquire your first credential"}
      </div>
    </div>
  );
};

export default RenderCredentials;
