import { XCircleIcon } from "@heroicons/react/24/outline";
import CopyToClipboard from "../CopyToClipboard";
import "./ShowRequest.css";
const ShowRequest = ({ title, method, endpoint, response, body, close }) => {
  return (
    <div className="ShowRequest">
      <h2>{title}</h2>
      <XCircleIcon className="Modal-close" onClick={close} />
      <div className="show-request-method">{method}</div>
      <h6> Endpoint:</h6>
      <CopyToClipboard
        inputValue={`https://sandbox-ssi.extrimian.com/v1${endpoint}`}
        textarea={false}
        disabled={true}
      />
      {body && <h6> Body:</h6>}

      {body && (
        <CopyToClipboard
          inputValue={JSON.stringify(body)}
          textarea={true}
          disabled={true}
        />
      )}
      <h6> Response:</h6>

      <CopyToClipboard
        inputValue={JSON.stringify(response)}
        textarea={true}
        disabled={true}
      />
    </div>
  );
};

export default ShowRequest;
