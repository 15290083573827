// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  min-height: 100vh;
  max-height: 100vh;
}

.App.darkMode {
  color: #f9f9f9;
  background: #222222;
}

.App.lightMode {
  color: #1e1f1f;
  background: #ffffff;
}

h2 {
  display: flex;
  align-items: center;
  margin: 0 0 1.5vw;
}

h6 {
  margin: 0 0 -0.5vw;
  font-size: 0.8vw;
  font-weight: 500;
}

.Modal-close {
  width: 2.5vw;
  height: 2.5vw;
  top: 0.5vw;
  right: 0;
  opacity: 0.3;
  position: absolute;
  cursor: pointer;
  will-change: opacity;
  transition: 0.3s;
}
.Modal-close:hover {
  opacity: 1;
}

.request-button-container {
  display: flex;
}

.request-button {
  width: 1.7vw;
  height: 1.5vw;
  margin-left: 1vw;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  color: #979797;
  border: 1px solid;
  transition: 0.3s;
  will-change: color;
}

.request-button:hover {
  color: #e7e7e7;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,iBAAiB;AACnB;;AAEA;EACE,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,UAAU;EACV,QAAQ;EACR,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,oBAAoB;EACpB,gBAAgB;AAClB;AACA;EACE,UAAU;AACZ;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,gBAAgB;EAChB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,eAAe;EACf,kBAAkB;EAClB,cAAc;EACd,iBAAiB;EACjB,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".App {\n  min-height: 100vh;\n  max-height: 100vh;\n}\n\n.App.darkMode {\n  color: #f9f9f9;\n  background: #222222;\n}\n\n.App.lightMode {\n  color: #1e1f1f;\n  background: #ffffff;\n}\n\nh2 {\n  display: flex;\n  align-items: center;\n  margin: 0 0 1.5vw;\n}\n\nh6 {\n  margin: 0 0 -0.5vw;\n  font-size: 0.8vw;\n  font-weight: 500;\n}\n\n.Modal-close {\n  width: 2.5vw;\n  height: 2.5vw;\n  top: 0.5vw;\n  right: 0;\n  opacity: 0.3;\n  position: absolute;\n  cursor: pointer;\n  will-change: opacity;\n  transition: 0.3s;\n}\n.Modal-close:hover {\n  opacity: 1;\n}\n\n.request-button-container {\n  display: flex;\n}\n\n.request-button {\n  width: 1.7vw;\n  height: 1.5vw;\n  margin-left: 1vw;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  cursor: pointer;\n  border-radius: 5px;\n  color: #979797;\n  border: 1px solid;\n  transition: 0.3s;\n  will-change: color;\n}\n\n.request-button:hover {\n  color: #e7e7e7;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
