import { useContext } from "react";
import { DidContext } from "../context";
import InvitationManualProcess from "../components/InvitationManualProcess";
import InvitationMessage from "../components/InvitationMessage";
import Modal from "../components/Modal";
import ShowRequest from "../components/ShowRequest";

const Invitation = () => {
  const context = useContext(DidContext);
  return (
    <div className="Invitation">
      <InvitationMessage />
      <hr></hr>
      <InvitationManualProcess />
      {context.openInvitationRequest && (
        <Modal>
          <ShowRequest
            method={"PUT"}
            title={"Invitation message"}
            endpoint={context.invitationRequest.endpoint}
            body={context.invitationRequest.param}
            response={context.invitationRequest.response}
            close={() => context.setOpenInvitationRequest(false)}
          />
        </Modal>
      )}
      {context.openProcessRequest && (
        <Modal>
          <ShowRequest
            method={"GET"}
            title={"Process invitation"}
            endpoint={context.processRequest.endpoint}
            body={context.processRequest.param}
            response={context.processRequest.response}
            close={() => context.setOpenProcessRequest(false)}
          />
        </Modal>
      )}
    </div>
  );
};

export default Invitation;
