import axios from "axios";

const baseUrl = process.env.REACT_APP_BASE_URL;
const getRequestUrl = (param) => {
  return `${baseUrl}${param}`;
};

const headers = {
  "Content-Type": "application/json",
};

export const request = async (method, endpoint, data) => {
  const url = getRequestUrl(endpoint);
  // Get
  if (method === "get") {
    try {
      const response = await axios(url);
      return response;
    } catch (error) {
      console.error("Error no esperado:", error);
    }
  }
  // Put
  else if (method === "put") {
    try {
      const response = await axios.put(url, data, { headers });
      return response;
    } catch (error) {
      console.error("Error no esperado:", error);
    }
  }
};
