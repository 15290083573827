// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.renderVc-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 1vw 0 2vw;
}
`, "",{"version":3,"sources":["webpack://./src/components/RenderCredentials/RenderCredential.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;EACf,mBAAmB;EACnB,kBAAkB;AACpB","sourcesContent":[".renderVc-container {\n  display: flex;\n  flex-wrap: wrap;\n  align-items: center;\n  padding: 1vw 0 2vw;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
